<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-6">
        <Input
          v-model="search.search_data"
          style="max-width: 350px"
          class="d-inline-block mb-2"
          placeholder="Search by title..."
          type="text"
        />
      </div>
      <div class="col-md-6 text-right">
        <div class="d-flex justify-content-end">
          <div
            class="form-group d-flex align-items-center justify-content-start mb-md-0"
          >
            <label class="mr-1">Show </label>
            <Select
              style="margin-right: 10px"
              @on-change="paginateChange"
              v-model="search.paginate"
              :clearable="false"
            >
              <Option :value="10"> 10</Option>
              <Option :value="30"> 30</Option>
              <Option :value="50"> 50</Option>
              <Option :value="80"> 80</Option>
              <Option :value="100"> 100</Option>
            </Select>
          </div>
          <Button @click="Filtering">Advance Filtering</Button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-card no-body>
          <b-card-body>
            <b-card-title> Job Lists</b-card-title>
            <b-row class="justify-content-between" v-if="filter_status">
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Select
                    v-model="search.status"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select Status"
                  >
                    <Option :value="1"> Draft</Option>
                    <Option :value="2"> published</Option>
                    <Option :value="3"> interviewing</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Select
                    v-model="search.company_id"
                    @on-change="SearchData"
                    :clearable="true"
                    filterable
                    placeholder="Select Company"
                  >
                    <Option
                      v-for="(company, index) in companyData"
                      :key="index"
                      :value="company.id"
                      v-if="companyData.length"
                      >{{ company.name }}
                    </Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Select
                    v-model="search.negotiable"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select negotiable"
                  >
                    <Option :value="1"> negotiable</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Select
                    v-model="search.salary_filter_type"
                    @on-change="salaryFilter"
                    :clearable="true"
                    placeholder="Salary Filter Type"
                  >
                    <Option :value="1"> Max</Option>
                    <Option :value="2"> Min</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Input
                    v-model="search.salary_from"
                    class="d-inline-block mr-1"
                    placeholder="From Salary"
                    type="text"
                    :disabled="!search.salary_filter_type"
                  />
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Input
                    v-model="search.salary_to"
                    class="d-inline-block mr-1"
                    placeholder="To Salary"
                    type="text"
                    :disabled="!search.salary_filter_type"
                  />
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Select
                    v-model="search.skill_filter_type"
                    @on-change="skillFilter"
                    :clearable="true"
                    placeholder="Skill Filter Type"
                  >
                    <Option :value="1"> AND</Option>
                    <Option :value="2"> OR</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="4">
                <div class="form-group">
                  <Select
                    :disabled="!search.skill_filter_type"
                    v-model="search.skill_id"
                    @on-change="SearchData"
                    filterable
                    multiple
                    :clearable="true"
                    placeholder="Select Skill"
                  >
                    <Option
                      v-for="(skill, index) in skills"
                      :key="index"
                      :value="skill.id"
                      v-if="skills.length"
                      >{{ skill.name }}
                    </Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Select
                    v-model="search.commitment"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select Work
              Availability"
                  >
                    <Option value="UNAVAILABLE"> UNAVAILABLE</Option>
                    <Option value="FULLTIME"> FULLTIME</Option>
                    <Option value="PARTTIME"> PARTTIME</Option>
                    <Option value="HOURLY"> HOURLY</Option>
                    <Option value="CONTRACTUAL"> CONTRACTUAL</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Select
                    v-model="search.seniority"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select seniority"
                  >
                    <Option value="ENTRY"> ENTRY</Option>
                    <Option value="MID"> MID</Option>
                    <Option value="SENIOR"> SENIOR</Option>
                    <Option value="MANAGER"> MANAGER</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="2">
                <div class="form-group">
                  <Select
                    v-model="search.workplace_type"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select Work Place Type"
                  >
                    <Option value="REMOTE"> REMOTE</Option>
                    <Option value="ONSITE"> ONSITE</Option>
                    <Option value="HYBRID"> HYBRID</Option>
                  </Select>
                </div>
              </b-col>
              <b-col cols="6" lg="3">
                <div class="form-group">
                  <Input
                    v-model="search.required_experience_from"
                    class="d-inline-block mr-1"
                    placeholder="From Experience"
                    type="text"
                  />
                </div>
              </b-col>
              <b-col cols="6" lg="3">
                <div class="form-group">
                  <Input
                    v-model="search.required_experience_to"
                    class="d-inline-block mr-1"
                    placeholder="To Experience"
                    type="text"
                  />
                </div>
              </b-col>

              <b-col cols="12" lg="3">
                <div class="form-group">
                  <DatePicker
                    v-model="from_date"
                    type="date"
                    placeholder="Job Created From Date"
                    style="width: 100%"
                  >
                  </DatePicker>
                </div>
              </b-col>

              <b-col cols="12" lg="3">
                <div class="form-group">
                  <DatePicker
                    v-model="to_date"
                    type="date"
                    placeholder="Job Created To Date"
                    style="width: 100%"
                  >
                  </DatePicker>
                </div>
              </b-col>
              <b-col cols="12" lg="3">
                <div class="form-group">
                  <DatePicker
                    v-model="estimated_from_date"
                    type="date"
                    placeholder="Stating estimated from date"
                    style="width: 100%"
                  >
                  </DatePicker>
                </div>
              </b-col>
              <b-col cols="12" lg="3">
                <div class="form-group">
                  <DatePicker
                    v-model="estimated_to_date"
                    type="date"
                    placeholder="Stating estimated to date"
                    style="width: 100%"
                  >
                  </DatePicker>
                </div>
              </b-col>
              <b-col cols="12" lg="3">
                <div class="form-group">
                  <DatePicker
                    v-model="deadline_from_date"
                    type="date"
                    placeholder="Job deadline From date"
                    style="width: 100%"
                  >
                  </DatePicker>
                </div>
              </b-col>
              <b-col cols="12" lg="3">
                <div class="form-group">
                  <DatePicker
                    v-model="deadline_to_date"
                    type="date"
                    placeholder="Job deadline to date"
                    style="width: 100%"
                  >
                  </DatePicker>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th class="text-center">Logo</th>
                  <th>Company Name</th>
                  <th>Job</th>
                  <th>Requirements</th>
                  <th>
                    Skills
                    <span style="font-size: 10px; text-transform: capitalize"
                      >(Blue=Required,Green=Optional)</span
                    >
                  </th>
                  <th
                    class="text-center"
                    v-if="permission.edit || permission.delete"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="JobData.data.length > 0"
                  v-for="(job, index) in JobData.data"
                  :key="index"
                >
                  <td class="align-middle text-center">
                    {{ JobData.meta.from + index }}
                  </td>
                  <td style="text-align: center">
                    <img
                      :src="
                        job.company_logo ? job.company_logo : getImage(160, 70)
                      "
                      height="70"
                      class="rounded mb-1 mb-md-0"
                    />
                  </td>
                  <td>
                    <p>
                      <b>{{ job.company_name }}</b>
                    </p>
                    <p><b>Created At: </b>{{ job.created_at }}</p>

                    <p><b>Created By: </b>{{ job.created_by }}</p>
                    <p>
                      <span
                        :class="
                          'badge ' +
                          (job.status === 1
                            ? 'badge-secondary'
                            : job.status === 2
                            ? 'badge-success'
                            : job.status === 3
                            ? 'badge-info'
                            : 'badge-light')
                        "
                      >
                        {{
                          job.status === 1
                            ? "Draft"
                            : job.status === 2
                            ? "Published"
                            : job.status === 3
                            ? "Interviewing"
                            : "Unknown Status"
                        }}
                      </span>
                    </p>
                  </td>

                  <td>
                    <h5 style="text-transform: capitalize">
                      <b>{{ job.title }} </b>
                    </h5>
                    <p><b>Length: </b>{{ job.length }}</p>
                    <p><b>Starting Date: </b>{{ job.estimated_start_date }}</p>
                    <p><b>Deadline: </b>{{ job.deadline }}</p>
                    <p>
                      <b>Salary:</b> {{ job.salary_min }} -
                      {{ job.salary_max }} BDT
                    </p>
                    <span
                      :class="
                        job.negotiable == 'Negotiable'
                          ? 'badge badge-success'
                          : 'badge badge-primary'
                      "
                    >
                      {{
                        job.negotiable == "Negotiable"
                          ? "Negotiable"
                          : "Show salary"
                      }}
                    </span>
                  </td>

                  <td>
                    <p><b>Vacancy: </b>{{ job.vacancy }}</p>
                    <p><b>Commitment: </b>{{ job.commitment }}</p>
                    <p>
                      <b>Experience: </b>{{ job.required_experience }} years
                    </p>
                    <p><b>Seniority: </b>{{ job.seniority }}</p>
                  </td>
                  <td style="width: 300px">
                    <span
                      v-for="(skill, num) in job.skills"
                      :key="num"
                      :class="
                        skill.type === 'Required'
                          ? 'badge badge-primary '
                          : 'badge badge-success'
                      "
                      style="margin-top: 2px; margin-left: 2px"
                    >
                      {{ skill.name }}
                    </span>
                  </td>
                  <td
                    style="width: 210px"
                    class="align-middle text-center"
                    v-if="permission.edit || permission.delete"
                  >
                    <Tooltip content="edit" placement="top-end">
                      <router-link
                        v-if="permission.edit"
                        class="btn-primary btn-sm"
                        :to="'/job/edit/' + job.id"
                        style="padding-bottom: 10px; margin-right: 5px"
                      >
                        <feather-icon icon="EditIcon" size="16" />
                      </router-link>
                    </Tooltip>

                    <Tooltip content="view" placement="top-end">
                      <router-link
                        v-if="permission.edit"
                        class="btn-success btn-sm"
                        :to="'/job/single/show/' + job.id"
                        style="padding-bottom: 10px"
                      >
                        <feather-icon icon="EyeIcon" size="16" />
                      </router-link>
                    </Tooltip>

                    <Tooltip content="applications" placement="top-end">
                      <router-link
                        class="btn-info btn-sm"
                        :to="'/job/applications/' + job.id"
                        style="padding-bottom: 10px; margin-left: 5px"
                      >
                        <feather-icon icon="UserIcon" size="16" />
                      </router-link>
                    </Tooltip>

                    <Tooltip content="matched candidate">
                      <router-link
                        class="btn-warning btn-sm d-inline-block mt-1"
                        :to="'/company/matched/candidate/' + job.id"
                        style="padding-bottom: 10px; margin-left: 5px"
                      >
                        <feather-icon icon="GridIcon" size="16" />
                      </router-link>
                    </Tooltip>

                    <Tooltip content="schedule">
                      <router-link
                        class="btn-primary btn-sm d-inline-block mt-1"
                        :to="'/schedule?company_job_id=' + job.id"
                        style="padding-bottom: 10px; margin-left: 5px"
                      >
                        <feather-icon icon="CalendarIcon" size="16" />
                      </router-link>
                    </Tooltip>

                    <Tooltip content="job status">
                      <button
                        class="btn btn-success btn-sm"
                        @click="jobStatusModal(job.id, job.company_id)"
                        style="margin-left: 5px; margin-top: 5px"
                      >
                        <feather-icon icon="BriefcaseIcon" size="16" />
                      </button>
                    </Tooltip>

                    <Tooltip content="delete">
                      <button
                        v-if="permission.delete"
                        style="margin-left: 5px; margin-top: 4px"
                        class="btn btn-danger btn-sm"
                        @click="Delete(job.id)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </button>
                    </Tooltip>
                  </td>
                </tr>

                <tr v-if="!JobData.data.length">
                  <td class="text-center" colspan="6">
                    <h4>No data found!</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Modal v-model="jobPublishModal" footer-hide title="Job Status">
            <b-form @submit.prevent="jobPublish()">
              <div class="row">
                <div class="col-xl-12">
                  <Select
                    filterable
                    style="margin-right: 10px"
                    v-model="jobPublishForm.status"
                  >
                    <Option value="1"> Draft</Option>
                    <Option value="2"> Published</Option>
                    <Option value="3"> Interviewing</Option>
                  </Select>
                </div>
                <div class="col-xl-4">
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="jobPublishForm.busy"
                    class="mt-2"
                  >
                    Update
                  </b-button>
                </div>
              </div>
            </b-form>
          </Modal>
          <b-card-body>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-muted"
                >Showing {{ JobData.meta.from }} to {{ JobData.meta.to }} of
                {{ JobData.meta.total }} entries</span
              >
              <pagination
                :data="JobData"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
              ></pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BCardTitle,
} from "bootstrap-vue";
import { HasError } from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        status: true,
      }),
      search: {
        search_data: "",
        company_id: "",
        negotiable: "",
        commitment: "",
        seniority: "",
        workplace_type: "",
        required_experience_from: "",
        required_experience_to: "",
        salary_from: "",
        salary_to: "",
        salary_filter_type: "",
        skill_filter_type: "",
        skill_id: [],
        from_date: "",
        to_date: "",
        estimated_from_date: "",
        estimated_to_date: "",
        deadline_from_date: "",
        deadline_to_date: "",
        paginate: 10,
        page: 1,
        status: "",
      },
      jobPublishModal: false,
      jobPublishForm: new Form({
        id: null,
        company_id: null,
        status: null,
      }),
      filter_status: false,
      from_date: "",
      to_date: "",
      estimated_from_date: "",
      estimated_to_date: "",
      deadline_from_date: "",
      deadline_to_date: "",
      skills: [],
      companyData: [],
      JobData: [],
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    BCardTitle,
  },
  mounted() {
    this.getResults();
    this.getSkills();
    this.getCompany();
  },
  methods: {
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },

    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      this.search.from_date = this.from_date
        ? this.formatDate(this.from_date)
        : "";
      this.search.to_date = this.from_date ? this.formatDate(this.to_date) : "";
      this.search.estimated_from_date = this.estimated_from_date
        ? this.formatDate(this.estimated_from_date)
        : "";
      this.search.estimated_to_date = this.estimated_to_date
        ? this.formatDate(this.estimated_to_date)
        : "";
      this.search.deadline_from_date = this.deadline_from_date
        ? this.formatDate(this.deadline_from_date)
        : "";
      this.search.deadline_to_date = this.deadline_to_date
        ? this.formatDate(this.deadline_to_date)
        : "";

      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/get/company/job${queryParams}`)
        .then((res) => {
          this.JobData = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    jobStatusModal(jobId, companyId) {
      this.jobPublishModal = true;
      this.jobPublishForm.id = jobId;
      this.jobPublishForm.company_id = companyId;
    },

    jobPublish() {
      this.jobPublishForm
        .put("/app/company/status/update/" + this.jobPublishForm.id)
        .then((res) => {
          this.getResults();
          this.jobPublishForm.company_id = null;
          this.jobPublishForm.status = null;
          this.s(res.data.message);
          this.jobPublishModal = false;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/app/company/job/" + id)
            .then((res) => {
              this.s(res.data.message);

              this.getResults();
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
        }
      });
    },

    getCompany() {
      axios
        .get("/app/all/company")
        .then((res) => {
          this.companyData = res.data.data;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    getSkills() {
      axios
        .get("/app/get/skill")
        .then((res) => {
          this.skills = res.data.skills.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Filtering() {
      this.filter_status = !this.filter_status;
    },

    salaryFilter() {
      if (
        this.search.salary_filter_type == null ||
        this.search.salary_filter_type == ""
      ) {
        this.search.salary_from = "";
        this.search.salary_to = "";
      }
      if (this.search.salary_from.length || this.search.salary_to.length) {
        this.SearchData();
      }
    },

    skillFilter() {
      if (
        this.search.skill_filter_type == null ||
        this.search.skill_filter_type == ""
      ) {
        this.search.skill_id = [];
      }
      if (this.search.skill_id.length) {
        this.SearchData();
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.jobs;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.required_experience_from": function (val) {
      this.SearchData();
    },
    "search.required_experience_to": function (val) {
      this.SearchData();
    },
    "search.salary_from": function (val) {
      this.SearchData();
    },
    "search.salary_to": function (val) {
      this.SearchData();
    },
    from_date: function (val) {
      this.SearchData();
    },
    to_date: function (val) {
      this.SearchData();
    },
    estimated_from_date: function (val) {
      this.SearchData();
    },
    estimated_to_date: function (val) {
      this.SearchData();
    },
    deadline_from_date: function (val) {
      this.SearchData();
    },
    deadline_to_date: function (val) {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
<style scoped>
td p {
  margin-bottom: 0;
}
</style>
